import React from "react"
import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const GuitarBridges = () => {

  return (
    <PageLayout>
      <SEO
        title="Guitar Bridges | Your Custom Build | King Electric Guitars"
        ogDescription="My custom guitar bodies are designed to accomodate all guitar bridges on the market so you can get just the tone you are after. Let's build your dream guitar!"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Guitar Bridges</h1>
            <p>
              What kind of bridge we use on your custom guitar can have a big impact on its design and sound. So, we have to take these things into consideration before we even <a href="/guitar-body/">choose the body style</a>. Some bridges need a long body, while others will work on any body style. There are two main categories of bridges: fixed and vibrato. But within those categories are many different styles.
            </p>
            <h2>Guitar Bridge Basics</h2>
            <p>
              First, some basics. The guitar bridge is a critical part of the electric guitar. It sets the string length and adjusts the string height above the fretboard. It allows the strings to vibrate at the correct pitch when played up and down the neck. 
            </p>
            <p>
              In some guitars, the strings pass through the tailpiece. With "thru-bridge" designs, the strings pass through the body of the guitar at the rear.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/guitar-bridges.jpg" alt="Guitar Bridges" />
            <p>
              In vibrato designs, the strings will pass through a moving element of the bridge itself. From there the strings go over the saddle of the bridge. The saddle is where we adjust intonation and string action. The strings run over the fretboard to the nut, which is the anchor point for the strings at the top of the neck. From there, the strings go to the tuners.
            </p>
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/guitar-bridges-tele-style.jpg" alt="Guitar Bridges Telecaster Style" />
            </div>
          }
          textRight={
            <div>
              <h2>Bridge Types</h2>
              <h3>Fixed Bridges</h3>
              <p>
                Fixed (or hardtail) bridges mount to the guitar and don't move. They are the simplest and most stable category of bridges.
              </p>
              <h4>Tele Style Bridges</h4>
              <p>
                Telecaster bridges have a metal base plate through which we insert a bridge pickup. The strings go through the guitar's body and ferrules on the back of the guitar hold them in place. Because this bridge mounts flat to the body, it is one of the simplest to design around. The neck can mount flat to the body, whereas high bridge designs need the <a href="/custom-guitar-necks/">neck to pitch back</a>. Leo Fender knew how to design something simple that he could replicate.
              </p>
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>Wrap Around Tailpiece</h4>
            <p>
              This may be the simplest and also most restrictive of guitar bridges. They are a simple bar that mounts to the guitar top. The strings go through the bar toward the back of the tailpiece and "wrap around" the bar. The problem with this is it provides very little adjustment for intonation. There are designs that will compensate for this by introducing notches. But only for standard tuning. And there are other versions with adjustable saddles. But they will still be somewhat limited in adjustments. At that point you might want to consider a Tune-O-Matic.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/guitar-bridges-wraparound.jpg" alt="Guitar Bridges Wrap Around Tailpiece" />
          </div>
        }
      />
      <PageWrapper color="grey">
         <TextBlock
            textPadded
            textLeft={
              <div>
                <img src="/images/guitar-bridges-tune-o-matic.jpg" alt="Guitar Bridges Tune-O-Matic" />
              </div>
            }
            textRight={
              <div>
                <h4>Tune-O-Matic</h4>
                <p>
                  Gibson introduced the <a href="https://en.wikipedia.org/wiki/Tune-o-matic" target="_blank" rel="noreferrer">Tune-O-Matic</a> bridge on the 1953 Super 400 electric archtop. And they followed up by putting it on the 1954 Les Paul. These bridges use fixed tailpieces. But they have a separate bar with adjustable saddles that rests on two adjustable polls. The bar can raise or lower to adjust string height. And the saddles are adjustable for intonation. Because it raises off the body of the guitar, it means we have two choices.  I can either recess the center of your guitar body or pitch the neck back (like an archtop) to compensate. This is not a problem at all, but something that we have to take into consideration in the design. This will be true of any raised bridge.
                </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
              </div>
            }
          />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>
              Vibrato Bridges
            </h3>
            <p>
              The proper term is vibrato, which means to fluctuate pitch. But Leo Fender liked to be confusing, so he used the term "tremolo" - which means a fluctuation in volume. The misused term became the popular term, and that's how it is. These systems allow the player to manipulate and arm (Tremolo Arm) as they play to drop (and sometimes raise) the pitch of all strings.
            </p>
          </div>
        }
      /> 
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>Bigsby</h4>
            <p>
              The <a href="https://www.bigsby.com/" target="_blank" rel="noreferrer">Bigsby</a> is one of the earliest of the vibrato systems. It's often found on Gretch and Gibson guitars - and even the occasional Telecaster. Like a wrap-around tailpiece, the strings insert through the rotating vibrato bar. They then wrap around under another roller bar. This creates a break angle for the strings to then go over a separate bar. This one with adjustable saddles like a Tune-O-Matic design. These are heavy-duty tremolo systems that hold tune well, but there are a couple of concerns. The break angle of the strings over the saddles is pretty extreme, which can make tuning difficult. It's also large and works best on large body guitars.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/guitar-bridges-bigsby.jpg" alt="Guitar Bridges Bigsby" />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/guitar-bridges-stratocaster.jpg" alt="Guitar Bridges Stratocaster Style" />
            </div>
          }
          textRight={
            <div>
              <h4>Stratocaster Bridge</h4>
              <p>
                The Stratocaster bridge is one of the most enduring tremolo systems. In part, this is due to its simplicity. This tremolo requires boring through the guitar body. It's a floating bridge with 6 independent saddles. The bridge features a 90-degree block that goes through the guitar body and is held at the back by 3 to 6 springs. The vintage design connects to the front of the guitar using 6 screws. Fender updated the system in 1986 to a "two-point" system held by only two screws. 
              </p>
              <p>
                Because of its popularity, many companies have since developed "improvements" to the design. Most notably of these are the systems developed by Callaham and Wilkinson.
              </p>
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>Jazzmaster Bridge</h4>
            <p>
              Unlike the Stratocaster, the Jazzmaster tremolo uses a separate adjustable bar for the saddles - like a Tune-O-Matic bridge. This gives the guitar a distinct resonant sound due to the long run of strings behind the saddles. The mechanics are also more refined than the simple springs of the Stratocaster and tend to hold tune well. But this tremolo system does need a long body to be able to fit the system.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/guitar-bridges-jazzmaster.jpg" alt="Guitar Bridges Jazzmaster" />
          </div>
        }
      />
      <PageWrapper color="grey">
         <TextBlock
            textPadded
            textLeft={
              <div>
                <img src="/images/guitar-bridges-floyd-rose.jpg" alt="Guitar Bridges Floyd Rose" />
              </div>
            }
            textRight={
              <div>
                <h4>Floyd Rose</h4>
                <p>
                  Many of the virtuosic heavy metal guitar styles of playing developed on the Stratocaster. But the strat vibrato had its technical limitations. It was finicky to set up, and heavy movement would knock the guitar out of tune. Floyd Rose, a jewler and guitarist, set out to develop a system that would stay in tune no matter how crazy his use of the vibrato arm. He added a locking nut and precision bridge design with micro tuners. Eddie Van Halen adopted it, setting off the dive bomb era of 1980's metal guitarists. Since then, they've released many varieties and enhancements.
                </p>
              </div>
            }
          />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>
              Duesenberg Les Trem II
            </h4>
            <p>
              A newer entry to the market, the <a href="https://store.duesenberg.de/en/tremolo-systems/34/duesenberg-les-trem-ii" target="_blank" rel="noreferrer">Les Trem II by Duesenberg</a> is a compact tremolo system. It's designed to work with guitars using a Tune-O-Matic bridge and stop tailpiece. This is a great option if you want to switch between a stop tail and vibrato. You can do so without heavy modification to the guitar - or if you're looking for bridge upgrades for a Tune-O-Matic. It has a wide range of adjustments and holds tuning well.
            </p>
            <h2>electric Guitar Saddles</h2>
            <p>
              Traditional Telecaster bridges use three saddles with two stings sharing each saddle. This introduces some challenges when it comes to adjusting string height and intonation. An old trick was to bend each side of the saddles to compensate for this problem. Compensated replacement saddles were later introduced. These improve intonation while maintaining the tele tone and look. But for alternate tunings, it can be tough to get the guitar intonated with the traditional 3 saddle method. So, there are 6-saddle set telecaster bridges available. These will allow for independent adjustment of each string.
            </p>
            <p>
              Stratocasters use 6 independent saddles.  They were traditionally bent nickel and steel which tends to produce a bright can clean tone. We used to call these "cheese graters", because you could rip your hand up on them as your strummed furious punk rock. They introduced heavier and smoother block saddles. These produce more mids and lows and tend to have more sustain. What you go with all depends on what sound you're after.
            </p>
            <p>
              Rolling saddles are also available on many bridges to help the string to move freely. These can be an improvement, especially on vibrato systems. They allow the strings to slide back and forth when you use the tremolo arm.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default GuitarBridges
